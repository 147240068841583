.total-header {
  text-align: center;
  margin-bottom: 60px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #023031;
}

.logo-header {
  width: 150px;
  margin-bottom: 40px;
  margin-left: 120px;
}

.login-name {
  display: flex;
  justify-content: flex-start;
  width: 200px;
  background-color: lightgray;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  margin-right: 120px;
}

.login-name p {
  font-weight: 400;
}

.login-name img {
  height: 23%;
  width: 23%;
  align-self: center;
  margin-left: 5px;
  margin-right: 15px;
}

.header-botton {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 70px;
}

.header-link {
  text-decoration: none;
  color: #2FC18C;
  font-size: 22px;
  background-color: #F0F2F5;
  color: #2FC18C;
  font-weight: 600;
  width: 33%;
  padding: 5px 0;
}

.header-link :hover {
  background-color: #036B52;
  color: white;
  padding: 0.5px 0;
}