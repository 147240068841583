.total-search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-fields {
  display: flex;
  margin-bottom: 45px;
}

.search-fields button {
  margin-left: 7px;
  height: 50px;
}

.search-fields input {
  display: flex;
  width: 100%;
  height: 50px;
}

.search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-results h2 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}

.album-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.album-cart {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border: 1px solid #E1E5EB;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: #F0F2F5;
  width: 11%;
  margin: 20px 20px;
  height: 290px;
}

.album-cart img {
  text-decoration: none;
  border: 1px solid #E1E5EB;
  border-radius: 15px;
  width: 170px;
}

.album-cart h2 {
  text-decoration: none;
  color: rgb(70, 67, 67);
  font-size: 15px;
  font-weight: 700;
}

.album-cart p {
  text-decoration: none;
  color: rgb(70, 67, 67);
  font-size: 15px;
}

.link-cart {
  text-decoration: none;
}

/* .album-cart :hover {
  background-color: #036B52;
  color: white;
} */