.main-album {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.album-cart2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border: 1px solid #E1E5EB;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: #F0F2F5;
  width: 17%;
  height: 400px;
  margin-right: 30px;
  margin-top: 25px;
  position: fixed;
  margin-right: 800px;
}

.album-cart2 img {
  text-decoration: none;
  border: 1px solid #E1E5EB;
  border-radius: 15px;
  width: 270px;
  margin-top: 10px;
}

.album-cart2 h1 {
  text-decoration: none;
  color: rgb(70, 67, 67);
  font-size: 15px;
  font-weight: 700;
}

.album-cart2 h3 {
  text-decoration: none;
  color: rgb(70, 67, 67);
  font-size: 15px;
}

.music-album-list {
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  width: 35%;
  margin-top: 13px;
}

.music-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 12px;
  background-color: #F0F2F5;
  border: 1px solid #E1E5EB;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 5px 35px;
  width: 120%;
}

.music-item p {
  width: 45%;
  color: rgb(70, 67, 67);
  font-size: 17px;
}

.favorits-list h2 {
  color: rgb(70, 67, 67);
  font-size: 30px;
  text-align: center;
}

.checkbox-box {
  visibility:hidden;
  font-size:20px;
  cursor:pointer;
  margin-bottom: 18px;
}
.checkbox-box:before {
 content: "\2606";
 position: absolute;
 visibility:visible;
 color: #2FC18C;
}
.checkbox-box:checked:before {
 content: "\2605";
 position: absolute;
 color: #2FC18C;
}