.profile-all{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 40%;
  align-self: center;
  justify-self: center;
  border: 1px solid #E1E5EB;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: #F0F2F5;
  text-align: start;
  padding: 45px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.profile-button {
  justify-self: flex-end;
  margin-right: 30px;
}

.profile-img {
  width: 180px;
  height: 180px;
  border: 1px solid #E1E5EB;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-left: 30px;
}

.profile-namediv {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.profile-namediv h2 {
  color: rgb(135, 129, 129);
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0;
}

.profile-namediv p {
  font-size: 19px;
  font-weight: 400;
  margin-top: 0;
}

.profile-emaildiv {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.profile-emaildiv h2 {
  color: rgb(135, 129, 129);
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0;
}

.profile-emaildiv p {
  font-size: 19px;
  font-weight: 400;
  margin-top: 0;
}

.profile-discdiv {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.profile-discdiv h2 {
  color: rgb(135, 129, 129);
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0;
}

.profile-discdiv p {
  font-size: 19px;
  font-weight: 400;
  margin-top: 0;
}

.profileEdit-full {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileEdit-full label {
  width: 50%;
  margin-bottom: 30px;
}

.profileEdit-full input {
  margin-top: 10px;
}